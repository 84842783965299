
body {
    font-family: var(--brand-font-family-oswald);
}

.bg-primary {
    background-color: var(--brand-menu-primary) !important;
}

@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1024px;
    }
}

.category-container {
    width: 100%;
    height: 295px;
    padding: 20px;
}

.category-item {
    aspect-ratio: 1 / 1;
    height: 100%;
    border-radius: .75em;
    position: relative;
    margin: 0 auto;
}

.category-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.75em;
    object-position: left;
}

.category-item label {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: var(--falcon-white);
    background: var(--brand-menu-primary);
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 0;
    font-family: var(--brand-font-family-oswald);
    font-size: 1.5em;
    letter-spacing: .15em;
    border-radius: 0 0 0.5em 0.5em;
}

@media (max-width: 575px) {
    .category-container {
        height: 170px;
    }

    .category-item label {
        font-size: 1.005em;
        text-transform: uppercase;
        padding-top: 0;
        padding-bottom: 0;
        bottom: 0;
    }
}

@media (max-width: 320px) {
    .category-container {
        height: 100%;
    }

    .category-item label {
        font-size: .915em;
        text-transform: uppercase;
        padding-top: 3px;
        padding-bottom: 3px;
        bottom: 0;
    }
}
