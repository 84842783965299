:root {
    --brand-font-family-fira: 'gloowe-open-sans', sans-serif;
    --brand-font-family-rubik: 'Rubik', sans-serif;
    --brand-font-family-oswald: 'gloowe-oswald'; /*'Oswald', sans-serif;*/
    --brand-menu-primary: #927e67;
    --falcon-breadcrumb-divider-color: #fff;
}

a {
    color: var(--brand-menu-primary) !important;
}

.bg-gloowe-primary {
    color: #fff;
    background: var(--gloowe-blue);
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.body-wrapper {
    position: relative;
    overflow: hidden;
}

.body-wrapper:before {
    transition-property: all;
    transition-duration: .25s;
    transition-timing-function: ease-out;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--brand-color-primary);
    content: " ";
    z-index: 899;
    visibility: hidden;
    opacity: 0;
}

#content {
    position: relative;
    overflow: hidden;
}

.page-title {
    display: none;
    position: relative;
    padding: 6rem 0 7rem;
}

#footer {
    margin-top: 2em;
}

#footer .footer-second-row {
    font-size: .9em;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    letter-spacing: .15em;
}

@media (max-width: 2700px) {
    .page-title {
        padding: 0;
        display: block;
        width: 1200px;
        margin: 0 auto;
    }
}

@media (max-width: 1500px) {
    .page-title {
        padding: 0;
        display: block;
        width: auto;
    }

    #footer .footer-second-row {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

@media (max-width: 991px) {
    #content {
        max-width: 780px;
        margin: 60px auto 0;
    }

    .page-title {
        display: block;
        width: auto;
    }
}

@media (max-width: 767px) {
    .page-title {
        display: block;
        width: auto;
    }
}

@media (max-width: 575px) {
    #footer {
        padding-bottom: 0;
    }
}

@media (max-width: 320px) {
    #footer {
        margin-top: 40px;
        padding-bottom: 0;
    }
}