.category-content header {
    background: var(--brand-menu-primary);
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
    padding: 10px 15px 15px;
}

.category-content header:last-of-type {
    margin-bottom: 20px;
}

.category-content .word {
    height: 18px;
    margin-bottom: 8px;
    margin-right: 8px;
    background: var(--brand-menu-primary);
    border-radius: 10px;
    display: inline-block;
}

.category-content .paragraph {
    margin-bottom: 20px;
}

.category-content section {
    overflow: hidden;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/* CONTENT */
.consumable-content {
    border-radius: 10px;
    margin-top: 10px;
    padding: 20px;
    -webkit-transform-origin: top center;
    transform-origin: top center;
}

.consumable-item {
    border-radius: 10px;
}

.consumable-image {
    width: 255px;
    object-fit: cover;
}

.border-10px {
    border-radius: 10px;
}

.border-top-10px {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.border-bottom-10px {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.consumable-item .border-bottom-10px {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

@media (max-width: 600px) {
    .category-content .header .word {
        height: 30px;
    }

    .category-content .word {
        height: 14px;
        margin-bottom: 5px;
        margin-right: 5px;
    }

    .category-content .paragraph {
        margin-bottom: 20px;
    }

    .consumable-content {
        padding-left: 15px;
        padding-top: 0;
    }
}

@media (min-width: 375px) {
    .consumable-image {
        width: 310px;
    }
}

@media (min-width: 425px) {
    .consumable-image {
        width: 355px;
    }
}

@media (min-width: 576px) {
    .consumable-image {
        width: 196px;
    }
}

.aspect-radio-1-1 {
    aspect-ratio: 1 / 1 !important;
    /*object-fit: contain;*/
    object-fit: cover;
}