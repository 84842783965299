
@font-face {
    font-family: 'gloowe-open-sans';
    font-style: normal;
    font-weight: 300;
    src: url('../src/assets/fonts/Open_Sans/OpenSans-Light.ttf') format('truetype');
    /*src: url('fonts/Open_Sans/OpenSans-Light.eot?#iefix') format('embedded-opentype'), */
    /* !* IE6-IE8 *!*/
    /*url('fonts/Open_Sans/OpenSans-Light.woff2') format('woff2'), */
    /* !* Super Modern Browsers *!*/
    /*url('fonts/Open_Sans/OpenSans-Light.woff') format('woff'), */
    /* !* Pretty Modern Browsers *!*/
    /*url('fonts/Open_Sans/OpenSans-Light.ttf')  format('truetype'), */
    /* !* Safari, Android, iOS *!*/
    /*url('fonts/Open_Sans/OpenSans-Light.svg#svgFontName') format('svg'); */
    /* !* Legacy iOS *!*/
}

@font-face {
    font-family: 'gloowe-open-sans';
    font-style: normal;
    font-weight: 400;
    src: url('../src/assets/fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
    /*src: url('fonts/Open_Sans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!*/
    /*url('fonts/Open_Sans/OpenSans-Regular.woff2') format('woff2'), !* Super Modern Browsers *!*/
    /*url('fonts/Open_Sans/OpenSans-Regular.woff') format('woff'), !* Pretty Modern Browsers *!*/
    /*url('fonts/Open_Sans/OpenSans-Regular.ttf')  format('truetype'), !* Safari, Android, iOS *!*/
    /*url('fonts/Open_Sans/OpenSans-Regular.svg#svgFontName') format('svg'); !* Legacy iOS *!*/
}

@font-face {
    font-family: 'gloowe-open-sans';
    font-style: normal;
    font-weight: 700;
    src: url('../src/assets/fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');
    /*src: url('fonts/Open_Sans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!*/
    /*url('fonts/Open_Sans/OpenSans-Bold.woff2') format('woff2'), !* Super Modern Browsers *!*/
    /*url('fonts/Open_Sans/OpenSans-Bold.woff') format('woff'), !* Pretty Modern Browsers *!*/
    /*url('fonts/Open_Sans/OpenSans-Bold.ttf')  format('truetype'), !* Safari, Android, iOS *!*/
    /*url('fonts/Open_Sans/OpenSans-Bold.svg#svgFontName') format('svg'); !* Legacy iOS *!*/
}

@font-face {
    font-family: 'gloowe-open-sans';
    font-style: italic;
    font-weight: 400;
    src: url('../src/assets/fonts/Open_Sans/OpenSans-Italic.ttf') format('truetype');
    /*src: url('fonts/Open_Sans/OpenSans-Italic.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!*/
    /*url('fonts/Open_Sans/OpenSans-Italic.woff2') format('woff2'), !* Super Modern Browsers *!*/
    /*url('fonts/Open_Sans/OpenSans-Italic.woff') format('woff'), !* Pretty Modern Browsers *!*/
    /*url('fonts/Open_Sans/OpenSans-Italic.ttf')  format('truetype'), !* Safari, Android, iOS *!*/
    /*url('fonts/Open_Sans/OpenSans-Italic.svg#svgFontName') format('svg'); !* Legacy iOS *!*/
}

@font-face {
    font-family: 'gloowe-oswald';
    font-style: normal;
    font-weight: 300;
    src: url('../src/assets/fonts/Oswald/Oswald-Light.ttf') format('truetype');
    /*src: url('fonts/Oswald/Oswald-Light.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!*/
    /*url('fonts/Oswald/Oswald-Light.woff2') format('woff2'), !* Super Modern Browsers *!*/
    /*url('fonts/Oswald/Oswald-Light.woff') format('woff'), !* Pretty Modern Browsers *!*/
    /*url('fonts/Oswald/Oswald-Light.ttf')  format('truetype'), !* Safari, Android, iOS *!*/
    /*url('fonts/Oswald/Oswald-Light.svg#svgFontName') format('svg'); !* Legacy iOS *!*/
}

@font-face {
    font-family: 'gloowe-oswald';
    font-style: normal;
    font-weight: 400;
    src: url('../src/assets/fonts/Oswald/Oswald-Regular.ttf') format('truetype');
    /*src: url('fonts/Oswald/Oswald-Regular.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!*/
    /*url('fonts/Oswald/Oswald-Regular.woff2') format('woff2'), !* Super Modern Browsers *!*/
    /*url('fonts/Oswald/Oswald-Regular.woff') format('woff'), !* Pretty Modern Browsers *!*/
    /*url('fonts/Oswald/Oswald-Regular.ttf')  format('truetype'), !* Safari, Android, iOS *!*/
    /*url('fonts/Oswald/Oswald-Regular.svg#svgFontName') format('svg'); !* Legacy iOS *!*/
}

@font-face {
    font-family: 'gloowe-oswald';
    font-style: normal;
    font-weight: 500;
    src: url('../src/assets/fonts/Oswald/Oswald-Medium.ttf') format('truetype');
    /*src: url('fonts/Oswald/Oswald-Medium.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!*/
    /*url('fonts/Oswald/Oswald-Medium.woff2') format('woff2'), !* Super Modern Browsers *!*/
    /*url('fonts/Oswald/Oswald-Medium.woff') format('woff'), !* Pretty Modern Browsers *!*/
    /*url('fonts/Oswald/Oswald-Medium.ttf')  format('truetype'), !* Safari, Android, iOS *!*/
    /*url('fonts/Oswald/Oswald-Medium.svg#svgFontName') format('svg'); !* Legacy iOS *!*/
}

@font-face {
    font-family: 'gloowe-oswald';
    font-style: italic;
    font-weight: 600;
    src: url('../src/assets/fonts/Oswald/Oswald-SemiBold.ttf') format('truetype');
    /*src: url('fonts/Oswald/Oswald-SemiBold.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!*/
    /*url('fonts/Oswald/Oswald-SemiBold.woff2') format('woff2'), !* Super Modern Browsers *!*/
    /*url('fonts/Oswald/Oswald-SemiBold.woff') format('woff'), !* Pretty Modern Browsers *!*/
    /*url('fonts/Oswald/Oswald-SemiBold.ttf')  format('truetype'), !* Safari, Android, iOS *!*/
    /*url('fonts/Oswald/Oswald-SemiBold.svg#svgFontName') format('svg'); !* Legacy iOS *!*/
}

@font-face {
    font-family: 'gloowe-oswald';
    font-style: italic;
    font-weight: 700;
    src: url('../src/assets/fonts/Oswald/Oswald-Bold.ttf') format('truetype');
    /*src: url('fonts/Oswald/Oswald-Bold.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!*/
    /*url('fonts/Oswald/Oswald-Bold.woff2') format('woff2'), !* Super Modern Browsers *!*/
    /*url('fonts/Oswald/Oswald-Bold.woff') format('woff'), !* Pretty Modern Browsers *!*/
    /*url('fonts/Oswald/Oswald-Bold.ttf')  format('truetype'), !* Safari, Android, iOS *!*/
    /*url('fonts/Oswald/Oswald-Bold.svg#svgFontName') format('svg'); !* Legacy iOS *!*/
}
