header .nav-tabs {
    overflow-x: auto;
    overflow-y:hidden;
    flex-wrap: nowrap;
}
header .nav-tabs .nav-item {
    font-family: var(--brand-font-family-oswald);
    white-space: nowrap;
}

header .avatar-name,
header .navbar-toggle-icon:after, .navbar-toggle-icon:before {
    background-color: var(--brand-menu-primary) !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    color: var(--brand-menu-primary);
    background-color: transparent;
    border-bottom: 2px solid var(--brand-menu-primary);
}

.container-header section {
    display: none;
}

@media (max-width: 575px) {
    .container-header {
        overflow: hidden;
        position: fixed;
        top: 0;
        bottom: 0;
        background: var(--falcon-badge-soft-light-background-color);
        transform: translateX(-100%);
        transition: transform ease-in-out 0.5s;
        z-index: 1;
    }

    .container-header.show {
        width: 100%;
        transform: translateX(0px);
    }

    .container-header .container-fluid .nav {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        justify-content: normal !important;
        border-bottom: 0;
    }

    .container-header .container-fluid .nav-item {
        border-bottom: 1px solid var(--brand-menu-primary);
        position: relative;
    }

    .container-header .container-fluid .nav-item:last-of-type {
        border-bottom: none;
        position: relative;
    }

    .container-header .container-fluid .nav-link {
        align-items: center;
        display: flex;
        font-size: 1.1rem;
        padding: 0.9rem 0;
        font-family: var(--brand-font-family-oswald);
    }

    .container-header section {
        display: block;
        border-radius: 0.75em;
    }

    .container-header .content-avatar {
        display: block;
        text-align: center;
    }
}
